// src/theme.js
export const lightTheme = {
  body: '#FFF',
  text: '#333',
  buttonBackground: '#007BFF',
  headerBackground: 'linear-gradient(135deg, #F0F9FF, #A6C1EE)',
  defaultBackground: 'linear-gradient(135deg, #fff0f0, #e8feff)',
  projectsBackground: 'linear-gradient(135deg, #ffcaaf, #ff5e62)',
  contactBackground: 'linear-gradient(135deg, #1CD8D2, #93EDC7)',
  projectCard: 'white',
  titleText: '#156760',
  sectionTitle: '#156760',
  sectionSubTitle: '#ae4e38',
  tagBackground: '#f0d9c7',
  skillsCard: 'white',
  iconColor: '#333',
  iconHoverColor: '#ae4e38',
  headerImage: "url('/images/forest-light.jpg')",
  headerShadow: '0px 0px 10px rgba(255, 255, 255, 0)',
  linkColor: '#1a73e8',
  linkHoverColor: '#0c47a1'
};

export const darkTheme = {
  body: '#121212',
  text: '#FAFAFA',
  buttonBackground: '#444',
  headerBackground: 'linear-gradient(135deg, #1e1e1e, #252525)',
  defaultBackground: 'linear-gradient(135deg, #0d0d0d, #1d1d1d)',
  projectsBackground: 'linear-gradient(135deg, #03001e, #b92b94)',
  contactBackground: 'linear-gradient(135deg, #C04848, #480048)',
  projectCard: '#303030',
  titleText: '#ffcc00',
  sectionTitle: '#ffcc00',
  sectionSubTitle: '#A2ECE6',
  tagBackground: '#121e26',
  skillsCard: '#333',
  iconColor: '#ddd',
  iconHoverColor: '#A2ECE6',
  headerImage: "url('/images/forest-dark.jpg')",
  headerShadow: '0px 0px 10px rgba(0, 0, 0, 0.8)',
  linkColor: '#8ab4f8',
  linkHoverColor: '#6a8cfc'
};
