import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import selfie from './images/me.png';

const AboutSection = styled.section`
  color: ${({ theme }) => theme.text};
  padding: 100px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media(min-width: 768px) {
    flex-direction: row;
    padding: 100px 50px;
  }

  .about-content {
    flex: 1;
    padding: 20px;
    text-align: center;

    @media(min-width: 768px) {
      text-align: left;
    }
    
    h2 {
      font-size: 2.5rem;
      color: ${({ theme }) => theme.sectionTitle};
      margin-bottom: 20px;
    }
    p {
      font-size: 1.1rem;
      line-height: 1.6;
      margin-bottom: 15px;
    }
  }

  .about-image {
    flex: 1;
    text-align: center;
    margin-bottom: 30px;

    @media(min-width: 768px) {
      margin-bottom: 0;
      text-align: right;
      padding-right: 50px;
    }

    img {
      width: 100%;
      max-width: 300px;
      height: auto;
      border-radius: 50%;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
  }
`;

const About = ({ theme }) => {
  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [theme]);

  return (
    <AboutSection id="about">
      <motion.div
        className="about-image"
        key={`image-${key}`} // Reset animation on theme change
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }} // Slow fade-in
      >
        <img src={selfie} alt="Otto Tuhkunen" />
      </motion.div>
      <div className="about-content">
        <h2>About Me</h2>
        <p>
          I am a passionate developer with a focus on web applications, particularly those utilizing <a target="_blank" href="https://react.dev/">React</a> and map-based tools like <a target="_blank" href="https://leafletjs.com/">Leaflet</a> and <a target="_blank" href="https://www.mapbox.com/">Mapbox</a>.
          I also have experience with <a target="_blank" href="https://expressjs.com/">Express.js</a> for backend development and handling SQL requests to <a target="_blank" href="https://www.postgresql.org/">PostgreSQL</a>. Many of my projects are published on <a target="_blank" href="https://www.heroku.com/">Heroku</a>.
        </p>
        <p>
          The aviation industry holds a special place in my heart, which is why many of my non-profit projects are related to flight operations and Air Traffic Services.
        </p>
        <p>
          I have a strong interest in designing and developing modern User Interfaces, which has led me to gain significant experience in frontend development.
        </p>
        <p>
          Currently, I am nearing the completion of my <em>Business Information Technologies</em> studies at Tampere University of Applied Sciences.
        </p>
        <p>Enjoy! 😊</p>
      </div>
    </AboutSection>
  );
};

export default About;
